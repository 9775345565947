import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';

import { CageSelector } from '@/components/molecules/CageSelector';
import { CageOption } from '@/components/molecules/CageSelector/CageSelector';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { OverviewCage, OverviewLocation } from '@/services/types';
interface OverviewCageWithMuxData extends OverviewCage {
    muxData?: any;
}

interface cageOption {
    id?: number;
    label?: string;
    value: number[];
    title?: string;
    disabled: boolean;
    checked: boolean;
}

export interface LocationVideoProps {
    locationName?: string;
    cageList: OverviewCageWithMuxData[];
    onCagesChanged: (cages: cageOption[], cageIds: number[], enableStream: boolean) => void;
    disableChips?: boolean;
}

const LocationVideo = ({
    locationName = '',
    cageList = [],
    disableChips = false,
    onCagesChanged = () => {},
}: LocationVideoProps) => {
    const [cageOptions, setCageOptions] = useState<cageOption[]>(
        cageList.map((cage) => ({
            id: cage.id,
            label: cage.name,
            value: [cage.id],
            title: cage.name,
            disabled: !cage.weight,
            checked: false,
        }))
    );

    const [allCageOptions, setAllCageOptions] = useState<CageOption>({
        label: 'All cages',
        value: cageList.map((cage) => {
            return cage.id;
        }),
        title: 'All cages',
        disabled: false,
        checked: false,
    });

    useEffect(() => {
        const updatedCageOptions = cageOptions.map((cageOption) => {
            const cage = cageList.find((c) => c.id === cageOption.id);
            if (!cage?.muxData) return { ...cageOption, checked: false };
            if (
                cage.muxData?.status === 'disabled' ||
                cage.muxData?.status === 'disconnected' ||
                (cage.muxData?.status === 'idle' && cage.muxData?.userId === null)
            )
                return { ...cageOption, checked: false };
            if (
                cage.muxData?.status === 'active' ||
                cage.muxData?.status === 'connected' ||
                cage.muxData?.status === 'idle'
            )
                return { ...cageOption, checked: true };
            return cageOption;
        });

        setCageOptions(updatedCageOptions);
    }, [cageList]);

    const addRemoveCage = (cageIds: number[], isOn: boolean) => {
        console.log('addRemoveCage:', cageIds, isOn);
        if (cageIds.length > 1) {
            const c: CageOption[] = cageOptions.map((cageOption) => ({
                ...cageOption,
                checked: isOn,
            }));
            const filteCageIdsByChecked = cageOptions.filter((c) => c.checked !== isOn);
            setCageOptions(c);
            onCagesChanged(
                cageOptions,
                filteCageIdsByChecked.map((c) => c.id),
                isOn
            );
        }

        if (cageIds.length === 1) {
            cageOptions.map((cageOption) => {
                if (cageOption.value[0] === cageIds[0]) {
                    cageOption.checked = isOn;
                }
            });
            onCagesChanged(cageOptions, cageIds, isOn);
        }
    };

    return (
        <>
            <Row>
                <PageTitleRow title={locationName}>
                    {!disableChips && (
                        <CageSelector
                            cageOptions={cageOptions}
                            cageAddRemove={addRemoveCage}
                            allCageOptions={allCageOptions}
                        />
                    )}
                </PageTitleRow>
            </Row>
        </>
    );
};

export default LocationVideo;
export { LocationVideo };
