import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import Loader from '@/components/atoms/Loader/Loader';
import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import VideoBox from '@/components/molecules/VideoBox';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import LocationVideoPageContent from '@/routes/Client/Location/pages/LocationVideo/LocationVideoPageContent';
import {
    useClientController_Overview,
    useVideoStreamController_startStream,
    useVideoStreamController_stopStream,
    useVideoStreamController_getBioscopeStream,
} from '@/services/hooks';
import type { OverviewCage, OverviewLocation } from '@/services/types';
import { mqttTopicsVideo } from './topics';
import styles from './LocationVideo.module.scss';

interface OverviewCageWithMuxData extends OverviewCage {
    muxData?: any;
}

const LocationVideoContainer = ({ clientId, locationId }) => {
    const {
        data: clientLocationsData,
        isLoading: isLoadingClientLocations,
        error: clientLocationsDataError,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const { t } = useTranslation();
    const { client, isConnected, subscribe, unsubscribe } = useMqtt();
    const user_id = useMe()?.state?.user?.id;
    const { mutateAsync: startStream } = useVideoStreamController_startStream();
    const { mutateAsync: stopStream } = useVideoStreamController_stopStream();
    const getBioscopeStream = useVideoStreamController_getBioscopeStream;

    const [locationData, setLocationData] = useState<OverviewCageWithMuxData[]>([]);
    const [activeStreamingBioscope, setActiveStreamingBioscope] = useState<any>([]);
    const [locationName, setLocationName] = useState<string>('');

    useEffect(() => {
        const location = clientLocationsData?.data?.locations?.find((x) => x.id === locationId);
        setLocationName(location?.name);
        const activeCages = location?.cages?.filter(
            (cage) => cage.activeBioscopes && cage.activeBioscopes.length > 0
        );

        // const mockCages = [
        //     {
        //         id: 1,
        //         name: 'Mock Cage 1',
        //         activeBioscopes: [1323],
        //         historical: 'false',
        //         muxData: null,
        //         weight: {
        //             ackedStatusList: [],
        //             day: '',
        //             growth: 0,
        //             livingWeight: 0,
        //             measStatusLevel: 0,
        //             measStatusList: [],
        //         },
        //     },
        //     {
        //         id: 2,
        //         name: 'Mock Cage 2',
        //         activeBioscopes: [1396],
        //         historical: 'false',
        //         muxData: null,
        //         weight: {
        //             ackedStatusList: [],
        //             day: '',
        //             growth: 0,
        //             livingWeight: 0,
        //             measStatusLevel: 0,
        //             measStatusList: [],
        //         },
        //     },
        // ];

        // const cages = activeCages ? [...activeCages, ...mockCages] : null;

        const cages = activeCages;

        if (cages) {
            setLocationData([
                ...cages.map((cage: OverviewCage) =>
                    'muxData' in cage ? cage : { ...cage, muxData: null }
                ),
            ]);
            for (const cage of cages) {
                const bioscopeId: number = cage.activeBioscopes[0];
                const { videoStatus: muxStatusTopic } = mqttTopicsVideo(bioscopeId);
                subscribe(muxStatusTopic, { qos: 1 });
            }
            return () => {
                for (const cage of cages) {
                    const bioscopeId: number = cage.activeBioscopes[0];
                    const { videoStatus: muxStatusTopic } = mqttTopicsVideo(bioscopeId);
                    unsubscribe(muxStatusTopic, { qos: 1 });
                }
            };
        }
    }, [clientLocationsData, locationId, subscribe, unsubscribe]);

    useEffect(() => {
        if (isConnected && client) {
            const handleMessage = async (topic: string, message: Buffer) => {
                const extractBioscopeIdFromTopic = (topic: string): number | null => {
                    const match = topic.match(/\/(\d+)\//);
                    return match ? parseInt(match[1], 10) : null;
                };

                if (topic.startsWith('bioscope/livestream')) {
                    try {
                        const parsedMessage = JSON.parse(message.toString());
                        const bioscopeId = extractBioscopeIdFromTopic(topic);

                        if (!bioscopeId) {
                            console.error('Missing bioscopeId in topic:', topic);
                            return;
                        }
                        setLocationData((prevLocationData) => {
                            return prevLocationData.map((cage) => {
                                if (cage.activeBioscopes[0] === bioscopeId) {
                                    console.log(
                                        'Updating muxData for bioscopeId:',
                                        parsedMessage,
                                        bioscopeId
                                    );
                                    return {
                                        ...cage,
                                        muxData: {
                                            streamId: parsedMessage.streamId,
                                            status: parsedMessage.status,
                                            muxStatus: parsedMessage.muxStatus,
                                            timestamp: parsedMessage.timestamp,
                                            userId: parsedMessage.startedByUserId || null,
                                            playback_id: parsedMessage.playbackId || null,
                                        },
                                    };
                                }
                                return cage;
                            });
                        });
                    } catch (error) {
                        console.error('Error parsing MQTT message:', error);
                    }
                }
            };

            client?.on('message', handleMessage);

            return () => {
                client?.off('message', handleMessage);
            };
        }
    }, [isConnected, client, locationData]);

    const handleCagesChanged = (cages, cageIds, enableStream) => {
        if (enableStream) {
            cageIds.map(async (cageId) => {
                const a = locationData.find((cage) => cage.id === cageId);
                try {
                    const response = await startStream({ bioscopeId: a.activeBioscopes[0] });
                    console.log('Stream started successfully:', response, response.statusText);
                } catch (error) {
                    console.error('Error starting stream:', error, error.response.data.message);
                    if (
                        error.response?.status === 403 ||
                        (error.response?.status === 400 &&
                            error.response.data.message ===
                                'Failed to start stream: Daily streaming quota exceeded')
                    ) {
                        setLocationData((prevLocationData) => {
                            return prevLocationData.map((cage) => {
                                if (cage.id === cageId) {
                                    return {
                                        ...cage,
                                        muxData: {
                                            ...cage.muxData,
                                            status: 'timeout',
                                        },
                                    };
                                }
                                return cage;
                            });
                        });
                    } else if (
                        error.response?.status === 400 &&
                        error.response.data.message !==
                            'Failed to start stream: Daily streaming quota exceeded'
                    ) {
                        setLocationData((prevLocationData) => {
                            return prevLocationData.map((cage) => {
                                if (cage.id === cageId) {
                                    return {
                                        ...cage,
                                        muxData: {
                                            ...cage.muxData,
                                            status: 'error',
                                        },
                                    };
                                }
                                return cage;
                            });
                        });
                    }
                }
            });
        }

        if (!enableStream) {
            cageIds.map(async (cageId) => {
                const a = locationData.find((cage) => cage.id === cageId);
                try {
                    const response = await stopStream({ bioscopeId: a.activeBioscopes[0] });
                    console.log('Stream stopped successfully:', response);
                    setLocationData((prevLocationData) => {
                        return prevLocationData.map((cage) => {
                            if (cage.id === cageId) {
                                return {
                                    ...cage,
                                    muxData: {
                                        ...cage.muxData,
                                        status: 'disabled',
                                    },
                                };
                            }
                            return cage;
                        });
                    });
                } catch (error) {
                    console.error('Error stopping stream:', error);
                }
            });
        }
    };

    const returnActiveStreamingVideos = (data: OverviewCageWithMuxData[]) =>
        data.filter(
            (location) =>
                location?.muxData &&
                (location.muxData.status === 'active' ||
                    location.muxData.status === 'error' ||
                    location.muxData.status === 'timeout' ||
                    // location.muxData.status === 'idle' ||
                    location.muxData.status === 'connected')
        );

    const isChipsDisable = (data: OverviewCageWithMuxData[]) => {
        return returnActiveStreamingVideos(data).some(
            (location) => location?.muxData?.userId && location?.muxData?.userId !== user_id
        );
    };

    return (
        <div className={styles.pageContent}>
            {isLoadingClientLocations && <Loader />}
            {clientLocationsDataError && <SomethingHappened />}
            {locationData.length > 0 && (
                <div>
                    <LocationVideoPageContent
                        locationName={locationName}
                        cageList={locationData}
                        onCagesChanged={handleCagesChanged}
                        disableChips={isChipsDisable(locationData)}
                    />
                </div>
            )}
            <UmerBox>
                <UmerBoxElementHeader title={t('Video')} />
                <section className={styles.videoGrid}>
                    {returnActiveStreamingVideos(locationData).length > 0 ? (
                        returnActiveStreamingVideos(locationData).map((location) => (
                            <VideoBox
                                key={location.activeBioscopes[0]}
                                cageName={location.name}
                                videoState={location.muxData.status || ''}
                                userId={location.muxData.userId}
                                videoId={`video-id-${location.activeBioscopes[0]}`}
                                videoSrc={location?.muxData?.playback_id}
                                timeRemaining={300}
                            />
                        ))
                    ) : (
                        <div>
                            <h2>{t('Live Video Streaming')}</h2>
                            <ul>
                                <li>
                                    {t(
                                        'Use the pen selectors above to choose the pen from where you want to stream video.'
                                    )}
                                </li>
                                <li>
                                    {t('You can toggle between multiple pens or select all pens.')}
                                </li>
                                <li>
                                    {t(
                                        'You can stream continuously for up to 5 minutes at a time. The stream is automatically ended after that.'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'The allocated time for live streaming is 10-minutes per day per bioscope, as live streaming interrupts fish recording. Thank you for understanding!'
                                    )}
                                </li>
                            </ul>
                        </div>
                    )}
                </section>
            </UmerBox>
        </div>
    );
};

export default LocationVideoContainer;
export { LocationVideoContainer };
